import AuthService from '../services/auth.service';

const userL = JSON.parse(localStorage.getItem('user'));
const expireL = JSON.parse(localStorage.getItem('expiresAt'));

function checkRole(state, role){
  if (state.user && state.user.roles) {
    return state.user.roles.includes(role);
  }
  return false;
}

export function hasRoleOf(role){
  if(role.length == 0)return true;
  return role.find(r => checkRole(auth.state,r))
}

export const auth = {
  namespaced: true,
  state: {
    loggedIn: userL ? true : false,
    user: userL ? userL : null,
    token: "",
    refreshToken: "",
    expiresAt: expireL ? expireL : 0,
  },

  getters: {
    currentUser: state => {
      return state.user;
    },
    isLoggedIn(state) {
      if (!state.loggedIn || !state.expiresAt) {
        return false;
      }
      return Date.now() < (state.expiresAt);
    },
    hasRole: (state) => (role) => {
      if(role.length == 0)return true;
      return checkRole(state, role);
    },
    isAdmin: state => {
      return checkRole( state, 'ROLE_ADMIN');
    },
    isAccManager: (state, getters) => {
      return getters.hasRole('ROLE_ACCOUNTMANAGER');
    },
    isTeacher: (state, getters) => {
      return getters.hasRole( 'ROLE_TEACHER');
    },
    isEplan: (state, getters) => {
      return getters.hasRole('ROLE_EPLAN');
    },
    isEplanManager: (state, getters) => {
      return getters.hasRole('ROLE_EPLANMANAGER');
    },
  },

  actions: {
    login({ commit }, user) {
      return AuthService.login(user).then(
        user => {
          commit('loginSuccess', user);
          return Promise.resolve(user);
        },
        error => {
          commit('loginFailure');
          return Promise.reject(error);
        }
      );
    },

    logout({ commit }) {
      commit('logout');
    },
    refreshToken({ commit }, accessToken) {
      commit('refreshToken', accessToken);
    },

    register({ commit }, user) {
      return AuthService.register(user).then(
        response => {
          commit('registerSuccess');
          return Promise.resolve(response.data);
        },
        error => {
          commit('registerFailure');
          return Promise.reject(error);
        }
      );
    }
  },
  mutations: {
    loginSuccess(state, user) {
      state.loggedIn = true;
      state.user = user;
      state.token = user.access_token;
      state.refreshToken = user.refresh_token;
      state.expiresAt = Date.now() + user.expires_in*1000;
      state.userName = user.username;
      localStorage.setItem('user', JSON.stringify(user));
      localStorage.setItem('expiresAt', JSON.stringify(state.expiresAt));

    },
    loginFailure(state) {
      state.loggedIn = false;
      state.user = null;
      state.expiresAt = null;
      localStorage.removeItem('user');
      localStorage.removeItem('expiresAt');
    },
    logout(state) {
      if(state.loggedIn){
        AuthService.logout();
      }
      state.loggedIn = false;
      state.user = null;
      state.expiresAt = null;
      state.token = null;
      localStorage.removeItem('user');
      localStorage.removeItem('expiresAt');
    },
    refreshToken(state, accessToken) {
      state.loggedIn = true;
      state.user = { ...state.user, access_token: accessToken };
    }
  }
};
