import api from './api';
import * as Const from '../config';



class AuthService {
  login(user) {
    return api.post(Const.AUTH_LOGIN, {
        username: user.username,
        password: user.password
      })
      .then((response) =>{
        if(response.data.access_token){
          localStorage.setItem('user', JSON.stringify(response.data.user));
        }
        if(response) return response.data;
      })
      .catch((error) => {
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      });
  }

  logout() {
    localStorage.removeItem('user');
//    return api
//        .post(Const.AUTH_LOGOUT);
  }
}

export default new AuthService();
