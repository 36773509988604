// stores/heights.js
import { defineStore } from 'pinia'

export const useHeightsStore = defineStore(
  'heights',
  {
    state: () => {
        return {
            toolbarHeight: 0,
            footerHeight: 0,
            mainHeight: 0,
            winHeight: 0,
        }
    },
    getters:{
        getUserHeight: (state) => state.winHeight - state.toolbarHeight - state.footerHeight,
    }
})