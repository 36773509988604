// stores/config.js
import { defineStore } from 'pinia';
import { ref, computed } from 'vue';
import TransferService from "@/services/transfer.service";
import * as Const from "@/config";
import {SJ_API} from "@/config";
import { consoleInfo } from 'vuetify/lib/util/console';

export const useConfigStore = defineStore(
    'config',
    () => {
        const cfg = ref([]);
        const readOnlyEplan = ref(false);

    //    init();
        function init() {
            return TransferService.get(Const.READ_KONFIG).then(
                file => {
                    if(file){
                        var cf = [];
                        for(const el of file){
                            cf.push(el);
                        }
                        cfg.value = cf;
                    }
                },
                error => {
                    consoleInfo("Cannot get SJ info.");
                    return '';
                },
            );
        };

        function writeKonfig(){
            return TransferService.post( Const.WRITE_KONFIG, cfg.value).then(
                ok => {
                    return Promise.resolve(ok);
                },
                error => {
                    commit('konfigFailure', 'config');
                    return Promise.reject(error);
                }
            )
        };

        function all(){
            let res = new Array(cfg.value.length);
            cfg.value.forEach(e => {
                res.push(e);
            })
            return res;
        };

        function save(arr){
            arr.forEach((value,key,map) => {
                let idx = find(key);
                cfg.value[idx].name = key;
                cfg.value[idx].value = value;
            })
            writeKonfig();
        }

        function get(key, def){
            var v = cfg.value[find(key)];
            var x;
            if(v == undefined){
                x = def;
                set(key, x);
            }
            else{
                x = v.value;
            }
            console.log("DOMAIN: " + key + "=" + x)
            return x;
        };

        function set(key, val){
            let idx = find(key);
            cfg.value[idx].value = val;
            writeKonfig();
        };

        function del(key){
            const index = cfg.values.find(key);
            cfg.values.splice(index, 1);
            writeKonfig();
        };

        function size(){
            return cfg.value.length;
        };

        function find(key){
            for(var i = 0; i < cfg.value.length; i++){
                let r = cfg.value[i];
                if(r.name === key)return i;
            }
            cfg.value.push({id:cfg.value.length+1, name:key, value:""})
            return cfg.value.length;
        }

        return {readOnlyEplan, init, writeKonfig, get, set, del, size, all, save};
    },
    {
        persist: true,
    },
);