import TransferService from "@/services/transfer.service";
import * as Const from "@/config";
import { defineStore } from 'pinia'
import { consoleInfo } from 'vuetify/lib/util/console';
import {consoleError} from "vuetify/lib//util/console";


export const useMenuStore = defineStore("menu", {
    state: () => {
        return {
            appMenuArray:  [
                { link:0, icon:"mdi-account-wrench", text: 'SuS-Konten', tooltip: "Anmeldekonten", role: 'ROLE_TEACHER', path: '/acc' },
                { link:1, icon:"mdi-chart-gantt", text: 'EPlan',  tooltip: "EPlan", role: 'ROLE_EPLAN', path: '/eplan' },
                { link:2, icon:"mdi-arrow-decision", text: 'Vertr.',  tooltip: "Vertretungen", role: 'ROLE_VERTRETUNG', path: '/vert' },
                { link:3, icon:"mdi-email-multiple-outline", text: 'Mailer',  tooltip: "E-Mails", role: 'ROLE_MAILER', path: '/mail' },
            ],

            leftMenuArray: [
                [   // SuS-Konten
                    { link:"/home", icon:"mdi-home", tooltip: "Konten", role: 'ROLE_TEACHER', path: '', topMenu: 0, rightTxt: false, title: 'Klassenliste' },
                    { link:"/allaccounts", icon:"mdi-account-group", tooltip: "Alle Konten", role: 'ROLE_TEACHER', path: '', topMenu: 0,rightTxt: false,  title: 'Gesamtliste' },
                    { link:"/moodle", icon:"mdi-school", tooltip: "Moodle Konten", role: 'ROLE_ACCOUNTMANAGER', path: '', topMenu: 0,rightTxt: false,  title: 'Moodle-Konten' },
                    { link:"/loaddata", icon:"mdi-upload", tooltip: "Konten hochladen", role: 'ROLE_ACCOUNTMANAGER', path: '', topMenu: 0, rightTxt: false, title: 'Kontenimport' },
                    { link:"/checkdata", icon:"mdi-compare-horizontal", tooltip: "Konten vergleichen", role: 'ROLE_ACCOUNTMANAGER', path: '', topMenu: 0, rightTxt: false, title: 'Kontenvergleich' },
                    { link:"/delrem", icon:"mdi-account-remove-outline", tooltip: "Alte Konten entfernen", role: 'ROLE_ACCOUNTMANAGER', path: '', topMenu: 0, rightTxt: false, title: 'LDAP aufräumen' },
                    { link:"/managenc", icon:"mdi-account-details-outline", tooltip: "Cloud-Konten verwalten", role: 'ROLE_ACCOUNTMANAGER', path: '', topMenu: 0, rightTxt: false, title: 'Cloud-Konten' },
                    { link:"/editroles", icon:"mdi-movie-open-edit-outline", tooltip: "Rollen editieren", role: 'ROLE_ADMIN', path: '', topMenu: 0, rightTxt: false, title: 'Rechte' },
                ],
                [   // EPlan
                    { link:"/", icon:"mdi-table-clock", tooltip: "EPlan", role: 'ROLE_EPLAN', path: '/eplan', topMenu: 5, rightTxt: true, title: 'Kolleginnen und Kollegen' },
                    { link:"/eplan", icon:null, tooltip: "EPlan", role: 'ROLE_EPLAN', path: '/eplan', topMenu: 5, rightTxt: true, title: 'Einsätze' },
                    { link:"/lehrer", icon:"mdi-human-male-board", tooltip: "Lehrer", role: 'ROLE_EPLAN', path: '/lehrer', topMenu: 4, rightTxt: true, title: 'Lehrer*innen' },
                    { link:"/kgroups", icon:"mdi-group", tooltip: "Klassengruppen", role: 'ROLE_EPLAN', path: '/kgroups', topMenu: 5, rightTxt: true, title: 'Klassen gruppieren' },
                    { link:"/kplan", icon:"mdi-school", tooltip: "Klassenplan", role: 'ROLE_EPLAN', path: '/kplan', topMenu: 5, rightTxt: true, title: 'Klassenpläne' },
                    { link:"/klassen", icon:"mdi-account-group", tooltip: "Klassen", role: 'ROLE_EPLAN', path: '/klassen', topMenu: 7, rightTxt: true, title: 'Klassenliste' },
                    { link:"/anrechnungen", icon:"mdi-view-list", tooltip: "Anrechnungen", role: 'ROLE_EPLAN', path: '', topMenu: 0, rightTxt: true, title: 'Anrechnungen' },
                    { link:"/anrechnungenpivot", icon:"mdi-calendar-check", tooltip: "Anrechnungspivot", role: 'ROLE_EPLAN', path: '', topMenu: 0, rightTxt: true, title: 'Anrechnungspivot' },
                    { link:"/ugruppen", icon:"mdi-chart-timeline", tooltip: "Unterrichtsgruppen", role: 'ROLE_EPLAN', path: '', topMenu: 0, rightTxt: true, title: 'Unterrichtsgruppen' },
                    { link:"/stdtafeln", icon:"mdi-gavel", tooltip: "Rahmenstundentafeln", role: 'ROLE_EPLAN', path: '', topMenu: 0, rightTxt: false, title: 'Stundentafeln' },
                    { link:"/sj", icon:"mdi-calendar-arrow-right", tooltip: "Schuljahre", role: 'ROLE_EPLANMANAGER', path: '', topMenu: 0, rightTxt: false, title: 'Schuljahre' },
                ],
                [   // Vertretung
                    { link:"/vert", icon:"mdi-table-edit", tooltip: "Vertretungen", role: 'ROLE_VERTRETUNG', path: '/vert', topMenu: 1, rightTxt: false, title: 'Vertretungen' },
                    { link:"/abw", icon:"mdi-human-male-board-poll", tooltip: "Abwesenheiten", role: 'ROLE_VERTRETUNG', path: '/abw', topMenu: 2, rightTxt: false, title: 'Abwesenheiten' },
                    { link:"/anw", icon:"mdi-table-eye", tooltip: "Freisetzungen", role: 'ROLE_VERTRETUNG', path: '/anw', topMenu: 3, rightTxt: false, title: 'Anwesenheiten' },
                    { link:"/auf", icon:"mdi-view-list", tooltip: "Angaben zu Freisetzungen", role: 'ROLE_VERTRETUNG', path: '/auf', topMenu: 6, rightTxt: false, title: 'Aufgaben' },
                    { link:"/plaene", icon:"mdi-clock-outline", tooltip: "Stundenpläne", role: 'ROLE_VERTRETUNG', path: '/plaene', topMenu: 3, rightTxt: false, title: 'Stundenpläne' },
                    { link:"/log", icon:"mdi-history", tooltip: "Maillog", role: 'ROLE_MAILER', path: '', topMenu: 0, rightTxt: false, title: 'Maillog' },
                    { link:"/conf", icon:"mdi-cog-play", tooltip: "Konfiguration der Mails", role: 'ROLE_ADMIN', path: '/conf', topMenu: 0, rightTxt: false, title: 'Vert.-Mail-Konfiguration' },
                ],
                [   // Mailer
                    { link:"/log", icon:"mdi-history", tooltip: "Maillog", role: 'ROLE_MAILER', path: '', topMenu: 0, rightTxt: false, title: 'Maillog' },
                    { link:"/mailgrps", icon:"mdi-format-list-group", tooltip: "Mail-Listen", role: 'ROLE_MAILER', path: '', topMenu: 0, rightTxt: false, title: 'Mail-Listen' },
                    { link:"/templ", icon:"mdi-form-textarea", tooltip: "Mail-Vorlagen", role: 'ROLE_ADMIN', path: '', topMenu: 0, rightTxt: false, title: 'Mail-Vorlagen' },
                    { link:"/mailercfg", icon:"mdi-cog-sync", tooltip: "Mailer-Konfiguration", role: 'ROLE_ADMIN', path: '', topMenu: 0, rightTxt: false, title: 'Mailer-CFG' },
                    { link:"/stdplcfg", icon:"mdi-cog-play", tooltip: "Stundenplanversand", role: 'ROLE_ADMIN', path: '', topMenu: 0, rightTxt: false, title: 'Mail-Pläne' },
                ],
            ],

            topMenuArray: [
                [], // keins: 0
                [   // Vertretungen: 1
                    { title: 'heute', link:'/heute' },
                    { title: 'morgen', link:'/morgen' },
                    { title: 'diese Woche', link:'/woche' },
                    { title: 'kommende Woche', link:'/kommende' },
                    { title: 'alle zukünftigen', link:'/alle' },
                    { title: 'Neues', link:'/neues' },
                    { title: 'ALLE', link:'/all' },
                ],
                [   // Abwesenheiten: 2
                    { title: 'klassen', link:'/klassen' },
                    { title: 'lehrerinnen', link:'/lehrerinnen' },
                    { title: 'heute', link:'/heute' },
                    { title: 'morgen', link:'/morgen' },
                    { title: 'diese Woche', link:'/woche' },
                    { title: 'kommende Woche', link:'/kommende' },
                ],
                [   // Anwesenheiten: 3
                    { title: 'diese Woche', link:'/woche' },
                    { title: 'kommende Woche', link:'/kommende' },
                    { title: 'in 2 Wochen', link:'/plus2' },
                    { title: 'in 3 Wochen', link:'/plus3' },
                    { title: 'in 4 Wochen', link:'/plus4' },
                ],
                [   // KuK Infos: 4
                    { title: 'KuK +/-', link:'/sum' },
                    { title: 'KuK meta', link:'/meta' },
                    { title: 'Stammdaten', link:'/stamm' },

                ],
                // to be added dynamically in init():
                // EPLAN: 5
                [],
                // Aufgaben: 6
                [],
                [   // Klassen Infos: 7
                    { title: 'Klassen +/-', link:'/sum' },
                    { title: 'Stammdaten', link:'/stamm' },
                ],
                [   // Vertretungs-Mail-Konfiguration: 8
                    { title: 'VMailkonfiguration', link:'/conf' },
                    { title: 'Mailtemplates', link:'/templ' },
                    { title: 'Konfigfile', link:'/file' },
                ],
            ],


            title: null,
            titleAdd: '',

            showAppMenu: false,
            selAppMenu: null,
            selAppMenuPart: '',

            leftMenu: [],
            selLeftMenu: null,
            selLeftMenuPart: '',

            topMenu: [],
            selTopMenu: null,
            selTopMenuPart: '',
            showTopMenu: false,
            showSJ: false,
            topMenuUrlAdd: null,

            route: '',
            selected: null,

            schuljahre: [],
            sjBereiche: Map,
            routeStack: [],

            loader: null,

            urlUp: null,
            urlUpTip: '',
            urlDown: null,
            urlDownTip: '',
            urlPrint: null,
            urlPrintTip: '',
            urlExcel: null,
            urlExcelTip: '',
            loaderThis: null,
        };
    },
    persist: {
        paths: ['title', 'showAppMenu', 'selAppMenu', 'leftMenu', 'selLeftMenu', 'topMenu', 'selTopMenu', 'showTopMenu',
            'showSJ', 'selAppMenuPart', 'selLeftMenuPart'],
        debug: true,
    },

    getters: {
        // static menues:
        getAppMenu(state){
            return state.appMenuArray;
        },

        getLeftMenu: (state) => {
            if(state.selAppMenu === null){
                state.selAppMenu = state.appMenuArray[0];
            }
            return state.leftMenuArray[state.selAppMenu.link];
        },

        getLink: (state) => (title) => {
            return state.ePlanMenu.find(m => m.title === title).link;
        },

        getFirstTopEntry: (state) => {
            if(state.topMenu != null && state.topMenu.length > 0){
                return state.topMenu[0];
            }
            return undefined;
        },

        getSelected: (state) => {
            return state.selected.get(state.selTopMenu);
        },

        getBereiche: (state) => (sj) => {
            return state.sjBereiche.get(sj);
        },

        isAccounts(){
            return this.selAppMenu && this.selAppMenu.link == 0;
        },
        isEPlan(){
            return this.selAppMenu && this.selAppMenu.link == 1;
        },
        isVert(){
            return this.selAppMenu && this.selAppMenu.link == 2;
        },
        isMail(){
            return this.selAppMenu && this.selAppMenu.link == 3;
        },
    },

    actions:{
        init(schuljahre, currSJ) {
            consoleInfo("MENU: Initializing menu-Store with " + schuljahre + ", curr:" + currSJ);
            let that = this;
            this.loader = null;
            this.schuljahre = schuljahre;
            this.selected = new Map();

//            if(this.sjBereiche == null || this.sjBereiche.length == 0){
            this.sjBereiche = new Map();

            var proms = new Array();
            for(const sj of this.schuljahre) {
                let path = Const.READ_EPLAN_BEREICHE + '/' + sj;
                proms.push(TransferService.get(path).then((data) => {
                    if(data){
                        let tme = [];
                        data.forEach((key,idx,arr) => {
                            tme.push({title: key, link: '/' + key});
                        });
                        this.sjBereiche.set(sj, tme);
                        consoleInfo("MENU: Init Bereich menu for " + sj);
                    }
                }));
            }

            proms.push(TransferService.get(Const.READ_VERT_AUFGABEN).then(data => {
                let tme = [];
                for (var key in data) {
                    var txt = data[key];
                    tme.push({title: txt, link: '/' + key});
                }
                that.topMenuArray[6] = tme;
            }));

            return Promise.all(proms).then(() => {
                consoleInfo("MENU: Read Bereiche for all Schuljahre " + this.schuljahre.length);

                if(this.selAppMenu == null) {
                    this.selAppMenu = this.appMenuArray[0];
                    this.selAppMenuPart = '/acc';
                    this.selLeftMenuPart = '';
                    this.selTopMenuPart = '';
                    this.showTopMenu = true;
                    this.loader = null;
                }

                that.topMenuArray[5] = this.calcBereichsMenu(currSJ);

                while(that.routeStack.length > 0 ){
                    let to = that.routeStack.pop();
                    consoleInfo("MENU: Popped route: " + to);
                    that.initRoute(to);
                }
            });
//            }
//            consoleInfo("MENU: already initialized.");
//            return new Promise(() => {return;});
        },

        calcBereichsMenu(sj){   // sjBereiche is a Map of String->String[] -> size!!!
            if(this.sjBereiche != null && this.sjBereiche.size > 0 && sj != null && sj.length > 0)
                return this.sjBereiche.get(sj);
            return new Array();
        },

        getCurrTopMenu(sj){
            let that = this;
            if(that.selLeftMenu != undefined && that.selLeftMenu.topMenu == 5){
                var menu = that.calcBereichsMenu(sj);
                that.topMenuArray[5] = menu;
                return menu;
            }
            return that.topMenu;
        },

        getCurrTopMenuIdx(sj){
            let menu = this.getCurrTopMenu(sj);
            if(menu != undefined && menu.length > 0){
                let idx = menu.findIndex(e =>
                    e.link === this.selTopMenu.link
                );
                if(idx >= 0) return idx;
            }
            return 0;
        },

        adjustBereich(sj, ber){
            consoleInfo("menu: adjustBereich called -> sj=" + sj + " ber=" + ber);
            var barr = this.sjBereiche.get(sj);
            if(barr){
                let idx = barr.findIndex(e => e.title.toUpperCase() === ber.toUpperCase());
                if(idx >= 0){
                    consoleInfo("menu: adjustBereich, found Bereich " + ber);
                    return ber;
                }
                if(barr.length > 0){    // barr is an array of strings -> length!!!
                    consoleInfo("menu: adjustBereich, found Bereich " + barr[0].title);
                    return barr[0].title;
                }
            }
            consoleInfo("menu: adjustBereich, no Bereich");
            return "";
        },

        setSelected(val){
          this.selected.set(this.selTopMenu, val);
        },

        async initRoute(to){
            let that = this;
            if(this.sjBereiche.length == 0){
                this.routeStack.push(to);
                consoleInfo("MENU: Pushing route " + to.fullPath +  " on stack " + that.routeStack.length);
                return;
            }
            consoleInfo("MENU: menuStore to: " + to.fullPath);

            let parts = to.fullPath.split('/');
            if (parts.length > 1) {
                parts[1] = '/' + parts[1];
                that.selAppMenuPart = parts[1];
                that.selAppMenu = that.appMenuArray.find(e => e.path === parts[1]);
                if(that.selAppMenu){
                    that.title = that.selAppMenu.text;
                    that.leftMenu = that.leftMenuArray[that.selAppMenu.link];
                }
                else{
                    that.title = "";
                    that.leftMenu = undefined;
                }
            }
            if (parts.length > 2) {
                parts[2] = '/' + parts[2];
                that.selLeftMenu = that.leftMenu.find(e => e.link === parts[2]);
                that.selLeftMenuPart = that.selLeftMenu.path;
                that.title = that.selLeftMenu.title;
                that.topMenu = that.topMenuArray[that.selLeftMenu.topMenu];
                that.showSJ = that.selLeftMenu.rightTxt;
                if(that.topMenu.length > 0){
                    that.showTopMenu = true;
                }
                else{
                    that.showTopMenu = false;
                }
            }
        },

        callUpload(file ) {
            if(this.loader != null && this.loader.up){
                this.loader.up(file, this.loader.this);
            }
        },
        callDownload() {
            if(this.loader != null && this.loader.down) {
                this.loader.down(this.loader.this);
            }
        },
        callPrint() {
            if(this.loader != null && this.loader.print) {
                this.loader.print(this.loader.this);
            }
        },
        callExcel() {
            if(this.loader != null && this.loader.excel) {
                this.loader.excel(this.loader.this);
            }
        },
        callPdf() {
            if(this.loader != null && this.loader.pdf) {
                this.loader.pdf(this.loader.this);
            }
        },

        // Menu in upper right part of the App-Bar
        //   { link:0, icon:"mdi-account-wrench", tooltip: "Anmeldekonten", role: 'ROLE_TEACHER', path: '/acc' },
        clickAppMenu(d){
            if(this.sjBereiche.size == 0){
                consoleError("MENU: Cannot clickAppMenu! Menu-Store not initialized");
                return;
            }

            this.selAppMenu = d.item;
            this.selAppMenuPart = d.item.path;
            this.leftMenu = this.leftMenuArray[this.selAppMenu.link];
            d.item = this.leftMenu[0];
            d.topSel = 0;
            this.clickLeftMenu(d);
//            this.clickLeftMenu({item:am[0], router:d.router});
        },

        // Menu to the left of the screen
        //  { link:"/home", icon:"mdi-home", tooltip: "Konten", role: 'ROLE_TEACHER', path: '', topMenu: 0, title: 'Klassenliste' },
        clickLeftMenu(d){
            if(this.sjBereiche.size == 0){
                consoleError("MENU: Cannot clickLeftMenu! Menu-Store not initialized");
                return;
            }
            this.selLeftMenu = d.item;
            this.selLeftMenuPart = d.item.path;
            this.title = d.item.title;
            const newTopMenu = this.topMenuArray[this.selLeftMenu.topMenu];
            this.showSJ = this.selLeftMenu.rightTxt;
            if(newTopMenu.length > 0){
                this.showTopMenu = true;
                d.item = newTopMenu[0];

                if(d.topSel > 0 && this.topMenu && this.topMenu.length >= d.topSel ){
                    const curTitle = this.topMenu[d.topSel].title;
                    d.item = newTopMenu.find(e => e.title === curTitle)
                    if(d.item == undefined){
                        d.item = newTopMenu[0];
                    }
                }
                this.topMenu = newTopMenu;
            }
            else{
                this.showTopMenu = false;
                d.item = this.selLeftMenu;
            }
            this.clickTopMenu(d);
//            d.router.pushRoute(this.selAppMenuPart + this.selTopMenuPart);
        },

// Menu just above some views
        clickTopMenu(d){
            this.titleAdd = '';
            this.selTopMenu = d.item;

            this.route = this.selAppMenuPart + this.selLeftMenuPart + d.item.link;
            if(this.topMenuUrlAdd != null){
                this.route += '/' + this.topMenuUrlAdd;
                this.topMenuUrlAdd = null;
            }
            consoleInfo("MENU: Pushing route: " + this.route);
            d.router.pushRoute(this.route);
            return d.item.link;
        },
    }
});
