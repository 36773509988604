<template>
  <v-responsive
      class="py-0 my-0"
      height="100%"
  >
    <v-card
          class="white pa-2 mx-0"
          tile
          ref="card"
      >
        <v-card-title>Konten:</v-card-title>
          <v-card-text>
            <v-row >
              <v-col cols="3">
                <v-select
                    v-model="sKlassen"
                    :items="mKlassen"
                    label="Klasse wählen"
                    @change="classSelected"
                    align="top"
                ></v-select>
              </v-col>
              <v-col cols="3" >
                <v-text-field
                    v-model="passwort"
                    label="Neues Passwort"
                ></v-text-field>
              </v-col>
<!--
              <v-col cols="3">
                <v-switch
                    v-model="ncon"
                    :label="`Klasse in cloud`"
                    @change="disKlasse"
                ></v-switch>
              </v-col>
-->
              <v-col cols="6"
              >
                <div v-if="konten.length>0"
                >
                  <v-dialog
                      v-model="dialog"
                      persistent
                  >
                    <template v-slot:activator="{}">
                      <v-btn
                          elevation="2"
                          @click="dialog = true"
                      >
                        Alle Passwörter zurücksetzen
                        <v-icon
                            right
                            color="red"
                        >
                          mdi-lock-alert</v-icon>
                      </v-btn>
                      <v-btn
                          elevation="2"
                          v-on:click="getExcel"
                      >
                        Namen als Excel-Datei
                        <v-icon
                            right
                            color="blue"
                        >
                          mdi-file-excel</v-icon>
                      </v-btn>
                    </template>
                    <v-card>
                      <v-card-title>Passwörter zurücksetzen</v-card-title>
                      <v-card-text class="text-h6">
                        Wirklich die Passwörter aller Accounts der Klasse {{sKlassen}} zurücksetzen?
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="green darken-1"
                            text
                            @click="dialog = false"
                        >
                          Nein
                        </v-btn>
                        <v-btn
                            color="red darken-1"
                            text
                            @click="dialog = false"
                            v-on:click="resetAll"
                        >
                          Zurücksetzen!
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </div>
              </v-col>

            </v-row>
          </v-card-text>
        </v-card>
    <v-card
            class="white pa-2 mx-0"
            tile
        >
          <v-data-table
              :headers="headers"
              :items="konten"
              :items-per-page="dataSize"
              item-key="loginName"
              fixed-header
              class="table-striped elevation-1"
              :hide-default-footer="true"
              :height="tabHeight"
              style="overflow:auto"
              v-resize="onMutate"
          >
            <template v-slot:[`item.anzeigeName`]="{ item }">
              <v-text-field v-model="editedItem.anzeigeName" :hide-details="true" dense single-line v-if="item.loginName === editedItem.loginName"></v-text-field>
              <span v-else>{{item.anzeigeName}}</span>
            </template>
            <template v-slot:[`item.loginName`]="{ item }">{{item.loginName}}</template>
            <template v-slot:[`item.nachname`]="{ item }">
              <v-text-field v-model="editedItem.nachname" :hide-details="true" dense single-line v-if="item.loginName === editedItem.loginName"></v-text-field>
              <span v-else>{{item.nachname}}</span>
            </template>
            <template v-slot:[`item.vorname`]="{ item }">
              <v-text-field v-model="editedItem.vorname" :hide-details="true" dense single-line v-if="item.loginName === editedItem.loginName"></v-text-field>
              <span v-else>{{item.vorname}}</span>
            </template>
            <template v-slot:[`item.email`]="{ item }">
              <v-text-field v-model="editedItem.email" :hide-details="true" dense single-line v-if="item.loginName === editedItem.loginName"></v-text-field>
              <span v-else>
                <a target="_blank" :href="`mailto:${item.email}`">
                  {{ item.email }}
                </a>
              </span>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <div v-if="item.loginName === editedItem.loginName">
                <v-icon color="red" class="mr-2" @click="close">
                  mdi-window-close
                </v-icon>
                <v-icon color="green"  @click="save">
                  mdi-content-save
                </v-icon>
              </div>
              <div v-else>

                <v-icon v-if="isAccManager" color="green" class="mr-2" @click="editItem(item)">
                  mdi-pencil
                </v-icon>

                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                        color="red"
                        @click="newPass(item)"
                        v-bind="attrs"
                        v-on="on"                      >
                      mdi-lock-alert
                    </v-icon>
                  </template>
                  <span>Passwort setzen</span>
                </v-tooltip>
              </div>
            </template>
          </v-data-table>
      </v-card>
  </v-responsive>
</template>

<script>
import TransferService from '../../services/transfer.service';
import {mapGetters} from "vuex";
import * as Const from '../../config';
import { consoleInfo } from 'vuetify/lib/util/console';
import {mapStores} from "pinia";
import {useMenuStore} from "@/stores/menu";
import {useHeightsStore} from "@/stores/heights";
import {useAlertStore} from "@/stores/alert";
import {DISKLASSE_NC} from "../../config";

export default {
  name: 'Home',

  data () {
    return {
      dialog: false,
      sKlassen: [],
      konten: [],
      passwort: '',
      login:'',
      editedIndex: -1,
      tabHeight: "100vH",
      editedItem: {
        anzeigeName: '',
        loginName: '-1',
        nachname: '',
        vorname: '',
        email: '',
      },
      klasse: '',
      ncon: false,

      dataSize: 0,

      headers: [
        { text: 'Klasse', align: 'start', sortable: true, value: 'klasse', },
        { text: 'KL', align: 'start', sortable: true, value: 'klasLehrer', },
        { text: 'Name', align: 'start', sortable: true, value: 'anzeigeName', },
        { text: 'Login', align: 'start', sortable: true, value: 'loginName', },
        { text: 'Nachname', align: 'start', sortable: true, value: 'nachname', },
        { text: 'Vorname', align: 'start', sortable: true, value: 'vorname', },
        { text: 'EMail', align: 'start', sortable: true, value: 'email', },
        { text: 'Actions', value: 'actions', sortable: false },

      ],

      mKlassen: [],
    }
  },

  computed: {
    ...mapGetters( 'auth', ['currentUser', 'isLoggedIn', 'hasRole', 'isAdmin', 'isTeacher', 'isAccManager', 'isEplan', 'isEplanManager' ]),
    ...mapStores(useHeightsStore, useMenuStore, useAlertStore),
  },

  mounted() {
    if (!this.isLoggedIn) {
      this.$router.push('/login');
    } else {
      this.loadConfig();

      TransferService.get(Const.READ_EXT_KLASSEN).then((data) => {
        if (data != undefined && data.length > 0) {
          this.mKlassen = data;
          this.dataSize = data.length;
        }
      });
    }
  },

  methods: {
    onMutate() {
      const heights = this.heightsStore;
      const card = this.$refs.card.$el.clientHeight;
      let h = heights.getUserHeight -card; // top menu - footer - v-card - fummel
      if( h > 20){
        this.tabHeight = " " + h + "px";
      }
      consoleInfo("Tabheight: " + this.tabHeight)
    },

    loadConfig() {
      this.alert=false;
    },

    resetAll(){
      let that = this;
      TransferService.post(Const.RESET_ALL_PW,  {klasse:this.sKlassen, pw:this.passwort}).then((res) => {
        that.alertStore.show('Passwörter für ' + that.sKlassen + ' auf ' + that.passwort + ' gesetzt.',
            res);
      });
    },

    getExcel(){
      return TransferService.getBinaryBlob(Const.READ_KLASSE_EXCEL + "/" + this.klasse, "application/vnd.ms-excel").then(
          response => {
//            const blob = new Blob([response], "application/vnd.ms-excel")
            const link = document.createElement('a')
            link.href = URL.createObjectURL(response)
            var d  = new Date();
            var datestring = "_" + d.getFullYear() + ("0"+(d.getMonth()+1)).slice(-2) + ("0" + d.getDate()).slice(-2);

            link.download = this.klasse + datestring + '.xls';
            link.click()
            URL.revokeObjectURL(link.href)

            return Promise.resolve(file);
          },
          error => {
            return Promise.reject(error);
          }
      )
    },


    editItem (item) {
      this.editedIndex = this.konten.indexOf(item);
      this.editedItem = Object.assign({}, item);
    },

    close () {
      setTimeout(() => {
//        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedItem.loginName = '';
        this.editedIndex = -1;
      }, 300)
    },

    save () {
      if (this.editedIndex > -1) {
        Object.assign(this.konten[this.editedIndex], this.editedItem)
        let that = this;
        that.login =  this.editedItem.loginName;
        TransferService.post(Const.UPDATE_EXT_ACCOUNT,  this.editedItem).then((res) => {
          that.alertStore.show("Kontodaten geschrieben für " + that.login + ".", res);
        });
      }
      this.close();
    },

    newPass(item){
      let that = this;
      that.login = item.loginName;
      TransferService.post(Const.WRITE_PW,  {id:that.login, pw:this.passwort}).then((res) => {
        that.alertStore.show('Passwort für ' + that.login + ' gesetzt', res);
      });
    },

    classSelected(item){
      console.log(item)

      let that = this;
      let q = [ item ];
      this.klasse = q;
      TransferService.post(Const.READ_EXT_ACCOUNTS,  q).then((res) => {
        if(res !== undefined){
          that.passwort = res.password;
          that.konten = res.accounts;
          that.klasse = item;
        }
      });
    },

    disKlasse(){
      let that = this;
      TransferService.post(Const.DISKLASSE_NC, this.klasse, this.ncon );
    }
  }
}

</script>

<style lang='scss'>
//$textSize:0.9rem;
//@import "~vue-aufgTable/dist/scss/bootstrap/tabulator_bootstrap4";
//@import "~vue-aufgTable/dist/scss/aufgTable";
@import "../../../node_modules/tabulator-tables/dist/css/tabulator.css";

tbody tr:nth-of-type(even) {
  background-color: rgba(236, 237, 237);
}

tbody tr:nth-of-type(odd) {
  background-color: rgb(250 ,250, 250);
}

.v-data-table-header {
  background-color: rgb(240, 240, 240);
  color: white;
}

.v-data-footer {
  background-color: rgb(250 ,250, 250);
}

.theme--light.v-data-table thead tr th {
  color: white;
}
</style>
