import Vue from 'vue';
import Vuex from 'vuex';

import { auth } from './auth.module';
import { domain } from './domain.module';
import { menu } from './menu.module';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    domain,
    menu,
  }
});
