// stores/heights.js
import { defineStore } from 'pinia'

export const useAlertStore = defineStore('alert', {
    state: () => {
        return {
            visible: false,
            type: "success",
            text: "",
            icon: "mdi-alert",
            forceEPlanUpdate: false,
        }
    },
    actions: {
        show(text, res, time, icon){
            if(res == undefined){
                res = "info";
            }
            else if(typeof res == "boolean"){
                res = res? "success" : "warning"
            }
            else if(typeof res != "string"){
                res = "info";
            }
            if(time == undefined){
                time = 4000;
            }
            if(icon == undefined || icon.length == 0){
                icon = "mdi-information-outline";
            }
            this.visible = true;
            this.type = res;
            this.text = text;
            this.icon = icon;
            console.log("Set alert: " + this.type + " alert: " + this.text);
            window.setTimeout(() => {
                this.visible = false;
                this.text = '';
                this.type = 'success';
                this.icon = "mdi-check-circle-outline";
                console.log("Reset alert: " + this.type + " alert: " + this.text);
            }, time)
        },
    }
})