import TransferService from "@/services/transfer.service";
import * as Const from "@/config";

export const menu = {
  namespaced: true,
  state: {
    title:null,

    TopMenu: [],
    eplanMenu: [],
    showTopMenu: false,
    subMenuPath: "data",

    appMenuIdx: 0,

    bereiche: [],
    bereich: null,

    urlup: null,
    uptip: '',
    urldown: null,
    downtip: '',
    loaderThis: null,
  },

  getters: {
    hasTopMenu: (state) => {
      return state.showTopMenu && state.TopMenu != null && state.TopMenu.length > 0;
    },
    getTopMenu: (state) => {
      return state.TopMenu;
    },
    getLeftMenu: (state) => {
      var lmenu = [
        [
          { link:"/home", icon:"mdi-home", tooltip: "Konten", role: 'ROLE_TEACHER' },
          { link:"/acc/allaccounts", icon:"mdi-account-group", tooltip: "Alle Konten", role: 'ROLE_TEACHER' },
          { link:"/acc/loaddata", icon:"mdi-upload", tooltip: "Konten hochladen", role: 'ROLE_ACCOUNTMANAGER' },
          { link:"/acc/checkdata", icon:"mdi-compare-horizontal", tooltip: "Konten vergleichen", role: 'ROLE_ACCOUNTMANAGER' },
          { link:"/acc/editroles", icon:"mdi-movie-open-edit-outline", tooltip: "Rollen editieren", role: 'ROLE_ADMIN' },
        ],
        [
          { link:"/eplan/kukpm", icon:"mdi-table-clock", tooltip: "EPlan", role: 'ROLE_EPLAN' },
          { link:"/eplan/kplan", icon:"mdi-school", tooltip: "Klassenplan", role: 'ROLE_EPLAN' },
          { link:"/eplan/lehrer", icon:"mdi-human-male-board", tooltip: "Lehrer", role: 'ROLE_EPLAN' },
          { link:"/eplan/anrechnungen", icon:"mdi-view-list", tooltip: "Anrechnungen", role: 'ROLE_EPLAN' },
          { link:"/eplan/anrechnungenpivot", icon:"mdi-calendar-check", tooltip: "Anrechnungspivot", role: 'ROLE_EPLAN' },
          { link:"/eplan/klassen", icon:"mdi-account-group", tooltip: "Klassen", role: 'ROLE_EPLAN' },
          { link:"/eplan/ugruppen", icon:"mdi-chart-timeline", tooltip: "Unterrichtsgruppen", role: 'ROLE_EPLAN' },
          { link:"/eplan/stdtafeln", icon:"mdi-gavel", tooltip: "Rahmenstundentafeln", role: 'ROLE_EPLAN' },
        ],
        [
          { link:"/vert/vertret", icon:"mdi-table-edit", tooltip: "Vertretungen", role: 'ROLE_VERTRETUNG' },
          { link:"/vert/abwesen", icon:"mdi-human-male-board-poll", tooltip: "Abwesenheiten", role: 'ROLE_VERTRETUNG' },
          { link:"/vert/anw", icon:"mdi-table-eye", tooltip: "Anwesenheit", role: 'ROLE_VERTRETUNG' },
          { link:"/vert/aufgaben", icon:"mdi-view-list", tooltip: "Angaben zur Anwesenheit", role: 'ROLE_VERTRETUNG' },
        ],
        [
          { link:"/mail/log", icon:"mdi-list-box-outline", tooltip: "Maillog", role: 'ROLE_VERTRETUNG' },
          { link:"/mail/stdpl", icon:"mdi-email-newsletter", tooltip: "Stundenpläne mailen", role: 'ROLE_VERTRETUNG' },
          { link:"/mail/config", icon:"mdi-form-select", tooltip: "Konfiguration", role: 'ROLE_ADMIN' },
        ],
      ];
      return lmenu[state.appMenuIdx];
    },

    getAppMenu: () => {
      return [
        { link:0, icon:"mdi-account-wrench", tooltip: "Anmeldekonten", role: 'ROLE_TEACHER' },
        { link:1, icon:"mdi-chart-gantt", tooltip: "EPlan", role: 'ROLE_EPLAN' },
        { link:2, icon:"mdi-arrow-decision", tooltip: "Vertretungen", role: 'ROLE_VERTRETUNG' },
        { link:3, icon:"mdi-email-multiple-outline", tooltip: "E-Mails", role: 'ROLE_VERTRETUNG' },
      ];
    },
    getUpTip: (state) => {
      return state.uptip;
    },
    getDownTip: (state) => {
      return state.downtip;
    },

    getBereiche: (state) => {
      return state.bereiche;
    },
    getLink: (state) => (title) => {
      return state.ePlanMenu.find(m => m.title === title).link;
    },
    getTitle: (state) => {
      return state.title;
    },
    getBereich: (state) => {
      return state.bereich;
    },
    hasUpload: (state) => {
      return state.urlup != null;
    },
    hasDownload: (state) => {
      return state.urldown != null;
    },
    getFirstEntry: (state) => {
      if(state.TopMenu != null && state.TopMenu.length > 0){
        return state.TopMenu[0];
      }
      return undefined;
    }
  },

  mutations: {
    init(state){
      state.showTopMenu = false;
      state.urlup = null;
      state.urldown = null;

    },

    showTopMenu(state, show){
      state.showTopMenu = show;
    },
    setTopMenu(state, menu){
      state.TopMenu = menu;
    },
    setEplanMenu(state, menu){
      state.eplanMenu = menu;
    },
    switchToEplanMenu(state){
      state.TopMenu = state.eplanMenu;
      state.showTopMenu = true;
    },
    setAppMenu(state, app){
      state.appMenuIdx = app;
    },

    setBereiche(state, bere){
      state.bereiche = bere;
    },
    setSubPath(state, bere){
      state.subMenuPath = bere;
    },
    setTitle(state, title){
      state.title = title;
    },
    setBereich(state, bereich){
      state.bereich = bereich;
    },

    setLoader(state, loader){
      if(loader === null ){
        state.urlup = null;
        state.urldown = null;
        state.uptip = '';
        state.downtip = '';
      }
      else{
        state.urlup = loader.up;
        state.urldown = loader.down;
        state.uptip = loader.upTip;
        state.downtip = loader.downTip;
        state.loaderThis = loader.this;
      }
    },
    callUpload(state, file ) {
      state.urlup(file, state.loaderThis);
    },
    callDownload(state ) {
      state.urldown(state.loaderThis);
    },
  },

  actions: {
    initAll(s, p){
      s.state.subMenuPath = p.spath;
      s.state.title =  p.title;
      s.state.showTopMenu = p.menu;
      s.state.bereich = p.bereich;
      if(p.loader === null || p.loader == undefined){
        s.state.urlup = null;
        s.state.urldown = null;
        s.state.uptip = '';
        s.state.downtip = '';
      }
      else{
        s.state.urlup = p.loader.up;
        s.state.urldown = p.loader.down;
        s.state.uptip = p.loader.upTip;
        s.state.downtip = p.loader.downTip;
        s.state.loaderThis = p.loader.this;
      }
      this.dispatch('menu/readBereiche');
      s.state.TopMenu = s.state.eplanMenu;
    },

    // Menu in upper right part of the App-Bar
    clickAppMenu(s, pl){
      s.state.appMenuIdx = pl.app;
      let am = menu.getters.getLeftMenu(s.state);
      let l = am[0].link;
      pl.router.pushRoute(l);
    },

// Menu just above some views
    clickTopMenu(s, change){
      let bereich = change.entry.title;
      let link = change.entry.link;
      const myArray = link.split("/");
      link = link.replace(myArray[2], s.state.subMenuPath);
      if(bereich == undefined){
        this.dispatch('menu/readBereiche').then(() => {
          this.commit('menu/showTopMenu', false);
          change.router.pushRoute('/eplan/kukpm');
        })
      }else{
        if(bereich != null && bereich !== this.getters['getBereich']){
          this.commit('menu/setBereich', bereich);
//          commit('showTopMenu', false);
          change.router.pushRoute(link);
        }
      }
    },

    readBereiche({commit}) {
      TransferService.get(Const.READ_EPLAN_BEREICHE).then(
          data => {
            var menu = [
              { title: 'KuK +/-', link:'/eplan/kukpm' },
            ];
            var bereiche = [];
            data.forEach((item) => {
              menu.push({
                title: item,
                link: '/eplan/' + this.state.menu.subMenuPath + '/bereich/' + item,
              })
              bereiche.push(item);
            })
            commit('setEplanMenu', menu);
//            commit('showTopMenu', true);
            commit('setBereiche', bereiche);
            return Promise.resolve(menu);
          },
          error => {
            if(error == 401){
              this.$store.dispatch('auth/logout');
              this.pushRoute('/login');
            }
            return Promise.reject(error);
          }
      )
    },
    pushRoute(params){
      // get comparable fullPaths
      let from  = this.$route.fullPath
      let to    = this.$router.resolve(params).route.fullPath

      if(from === to) {
        // handle any error due the redundant navigation here
        // or handle any other param modification and route afterwards
        return
      }

      // route as expected
      this.$router.push(params)
    },
  },
};
