import TransferService from '../services/transfer.service';
import * as Const from '../config';

export const domain = {
  namespaced: true,

  state: {
    konfig: [],
    konfMap: null,
    unchanged: 0,
    toChange: [],
    toCOld: [],
    toCreate: [],
    toDelete: [],
    doubles: [],

    dom: [],
  },

  getters: {
    getKonfig: state => {
      return state.konfig;
    },
    getKonfigLoaded: state => {
      return state.konfig != null;
    },
    getCfgItem: (state) => (item, def) => {
      var x = state.konfMap.get(item);
      if(x == undefined) x = def;
      console.log("DOMAIN: " + item + "=" + x)
      return x;
    },
    getToChange: state => {
      if(state.toChange !== undefined)return state.toChange;
      return [];
    },
    getToCOld: state => {
      if(state.toCOld !== undefined)return state.toCOld;
      return [];
    },
    getToNew: state => {
      if(state.toCreate !== undefined)return state.toCreate;
      return [];
    },
    getToDelete: state => {
      if(state.toDelete !== undefined)return state.toDelete;
      return [];
    },
    getDoubles: state => {
      if(state.doubles !== undefined)return state.doubbles;
      return [];
    },
    getData: (state) => (dom) => {
      return state.dom[dom];
    },
    isDataLoaded: (state) => (dom) => {
      return state.dom[dom] != null;
    },
  },

  mutations: {
    gotKonfig(state, n){
      state.konfig = n.file;
      var cf = new Map();
      for(const el of n.file){
        cf.set(el.name, el.value);
      }
      state.konfMap = cf;
    },
    konfigFailure(state){
      state.konfig = null;
    },
    putData(state, p){
      for(const e of state.konfig){
        if(e.name === p.name){
          e.value = p.value;
          break;
        }
      }
//      state.konfig[p.name] = p.value;
    },
    putAccUpdate(state, n){
      state.unchanged = n.au.unchanged;
      state.toChange = n.au.toChange;
      state.toCOld = n.au.toCOld;
      state.toCreate = n.au.toCreate;
      state.toDelete = n.au.toDelete;
    },
    gotDoubles(state, n){
      state.doubles = n;
    },

    gotFile(state, n){
      state.dom[n.dom] = n.file;
    },
    fileFailure(state, dom){
      state.dom[dom] = null;
    },

  },

  actions: {
    readAccState({commit}){
      return TransferService.get(Const.COMPACT_ACCOUNTS).then(
          data => {
            commit('putAccUpdate', {au: data} )
            return Promise.resolve(data);
          },
          error => {
            return Promise.reject(error);
          }
      )
    },

    readKonfig({commit}){
      return TransferService.get(Const.READ_KONFIG).then(
          file => {
            commit('gotKonfig', {file:file} )
            console.log("DOMAIN: Konfig loaded");
            return Promise.resolve(file);
          },
          error => {
            commit('konfigFailure', 'Konfig');
            return Promise.reject(error);
          }
      )
    },
    writeKonfig({commit, getters}){
      return TransferService.post( Const.WRITE_KONFIG, getters.getKonfig).then(
          ok => {
            return Promise.resolve(ok);
          },
          error => {
            commit('konfigFailure', 'Konfig');
            return Promise.reject(error);
          }
      )
    },

    searchDoubles({commit}){
      return TransferService.get(Const.READ_DUPLICATES).then(
          data => {
            commit('gotDoubles', data )
            return Promise.resolve(data);
          },
          error => {
            return Promise.reject(error);
          }
      )
    },

    // checkextdup
    checkextdup({commit}){
      return TransferService.get(Const.READ_EXT_DUPLICATES).then(
          data => {
            commit('gotDoubles', data )
            return Promise.resolve(data);
          },
          error => {
            return Promise.reject(error);
          }
      )
    },

    readDomain({commit}, dom){
      return TransferService.get( Const.EPLAN_DOMAIN_API + '/' + dom).then(
          file => {
            commit('gotFile', {file:file, dom: dom} )
            return Promise.resolve(file);
          },
          error => {
            commit('fileFailure',dom);
            return Promise.reject(error);
          }
      )
    },
    writeDomain({commit}, cont){
      return TransferService.postForm( Const.EPLAN_DOMAIN_API + '/' + cont.dom + '/upload', cont.file).then(
          ok => {
            return Promise.resolve(ok);
          },
          error => {
            commit('fileFailure', cont.dom);
            return Promise.reject(error);
          }
      )
    },
  },
};
