import Vue from 'vue'
import Vuex from 'vuex';
import Vuetify from './plugins/vuetify'

import App from './App.vue'
import { router } from './router';
import store from './store';
import axios from "axios";
import { consoleInfo } from 'vuetify/lib/util/console';

import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
//import 'moment';
import 'luxon';
import VueCookies from 'vue-cookies';

import VeeValidate from 'vee-validate';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import {
  faHome,
  faUser,
  faUserPlus,
  faSignInAlt,
  faSignOutAlt
} from '@fortawesome/free-solid-svg-icons';
library.add(faHome, faUser, faUserPlus, faSignInAlt, faSignOutAlt);


import vuetify from './plugins/vuetify'
import Component from "vue-class-component";
import VueLuxon from "vue-luxon";
import {createPinia, PiniaVuePlugin} from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import Router from "vue-router";

window.luxon = require('luxon');
window.XLSX = require('xlsx');
window.jspdf = require('jspdf');
window.autotable = require('jspdf-autotable');

// Register the router hooks with their names
Component.registerHooks([
  "beforeRouteEnter",
  "beforeRouteLeave",
  "beforeRouteUpdate"
]);

Vue.config.productionTip = false
import setupInterceptors from './services/setupInterceptors';

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);
//const pStore = useConfigStore(pinia) // pass the instance to our store, instead of inferring it from our Vue app

//pStore.init((cfg) => {
//    pStore.$state = cfg; // replace the store's state without our data

Vue.use(PiniaVuePlugin)
Vue.use(Router);
Vue.use(Vuex);
Vue.use(VeeValidate);
Vue.use(Vuetify);
Vue.use(VueLuxon);
//Vue.use(VueTabulator);
//    Vue.use(PiniaVuePlugin);
Vue.use(VueCookies);

setupInterceptors(store);

Vue.component('font-awesome-icon', FontAwesomeIcon);
    //Vue.component('vue-simple-context-menu', VueSimpleContextMenu);
import {useMenuStore } from '@/stores/menu';
import {useSchuljahreStore } from '@/stores/schuljahre';
import {useConfigStore } from '@/stores/config';
import { createStore } from 'vuex'

let app = new Vue({
    pinia,
    store,
    vuetify,
    router,
    render: h => h(App)
});


let that = this;
let useConfigStoreS = useConfigStore();
useConfigStoreS.init().then( () => {
    console.log("MAIN: Konfig loaded");
    let useSchuljahreS = useSchuljahreStore();
    useSchuljahreS.init().then( sj => {
        let currSJ = sj;
        let schuljahre = useSchuljahreS.getSchuljahre();
        consoleInfo("MAIN: Init set SJ=" + currSJ + " sizeof(schuljahre)=" + schuljahre.length);

        let menuS = useMenuStore();
        menuS.init(schuljahre, currSJ).then(() => {
            app.$mount('#app');
        })
    });

});
