// stores/heights.js
import { defineStore } from 'pinia'

export const useLoadStore = defineStore('load', {
    state: () => {
        return {
            visible: false,
            type: "success",
            text: "",
            icon: "mdi-alert",
            max: 100,
            cur: 0,
            windowTimer: null,
        }
    },
    actions: {
        show(text, res, icon, max){
            if(res == undefined){
                res = "info";
            }
            else if(typeof res == "boolean"){
                res = res? "success" : "warning"
            }
            else if(typeof res != "string"){
                res = "info";
            }
            if(icon == undefined || icon.length == 0){
                icon = "mdi-information-outline";
            }
            this.visible = true;
            this.type = res;
            this.text = text;
            this.icon = icon;
            this.cur = 0;
            this.max = max;
            if(max == undefined )this.max = 100;
            console.log("Show load dialog: " + this.type + " alert: " + this.text);
            this.windowTimer = window.setTimeout(() => {
                this.visible = false;
                this.text = '';
                this.type = 'success';
                this.icon = "mdi-check-circle-outline";
                console.log("Reset load dialog: " + this.type + " alert: " + this.text);
            }, 150*1000)
        },

        update(cur, text){
            this.cur = cur;
            if(text != undefined)this.text = text;
            window.clearTimeout(this.windowTimer);
            this.windowTimer = window.setTimeout(() => {
                this.visible = false;
                this.text = '';
                this.type = 'success';
                this.icon = "mdi-check-circle-outline";
                console.log("Reset load dialog: " + this.type + " alert: " + this.text);
            }, 150*1000);
        },

        finish(){
            this.cur = 0;
            this.visible = false;
            window.clearTimeout(this.windowTimer);
        },
    }
})