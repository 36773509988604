import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import '@mdi/font/css/materialdesignicons.css'
import {
    VRow,
    VCol,
    VTextField,
    VTooltip,
    VCheckbox,
    VSelect,
    VCard,
} from 'vuetify/lib';

import { Ripple, Intersect, Touch, Resize } from 'vuetify/lib/directives';
Vue.component('VueMask', () => import('v-mask'));
Vue.use(Vuetify, {
    components: { VRow, VTooltip, VCol, VTextField, VCheckbox, VSelect, VCard },
    directives: { Ripple, Intersect, Touch, Resize },
});

export default new Vuetify({
    theme: {
        themes: {
            dark: {
                primary: '#3f51b5',
                secondary: '#b0bec5',
                accent: '#8c9eff',
                error: '#b71c1c',
            },
            light: {
                primary: '#2196f3',
                secondary: '#009688',
                accent: '#8bc34a',
                error: '#f44336',
                warning: '#ff9800',
                info: '#cddc39',
                success: '#4caf50'
            },
        },
    },
    icons: {
        iconfont: 'mdiSvg', // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
    },
});
