<template>
  <div class="col-md-12">
    <div class="card card-container">
      <img
          id="profile-img"
          src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
          class="profile-img-card"
      />
      <form name="form">
        <div class="form-group">
          <label for="username">Username</label>
          <input
              v-model="user.username"
              v-validate="'required'"
              type="text"
              class="form-control"
              name="username"
          />
          <div
              v-if="errors.has('username')"
              class="alert alert-danger"
              role="alert"
          >Username is required!</div>
        </div>
        <div class="form-group">
          <label for="password">Password</label>
          <input
              v-model="user.password"
              v-validate="'required'"
              type="password"
              class="form-control"
              name="password"
          />
          <div
              v-if="errors.has('password')"
              class="alert alert-danger"
              role="alert"
          >Password is required!</div>
        </div>
        <div class="form-group d-flex justify-space-between">
          <button class="btn btn-primary btn-block" :disabled="loading" @click="handleLogin">
            <span v-show="loading" class="spinner-border spinner-border-sm"></span>
            <span>Login</span>
          </button>
<!--          <button class="btn btn-primary btn-block" :disabled="loading" @click="handleOauth">
            <span v-show="loading" class="spinner-border spinner-border-sm"></span>
            <span>Use BKEST to login</span>
          </button> -->
        </div>
        <div class="form-group">
          <div v-if="message" class="alert alert-danger" role="alert">{{message}}</div>
        </div>
      </form>
<!--      <a href="http://localhost/oauth/login/bkest">Log in</a>-->
    </div>
  </div>
</template>

<script>
import User from '../models/user';
import {mapGetters} from "vuex";
import axios from "axios";
import * as Const from "@/config";

export default {
  name: 'Login',
  data() {
    return {
      user: new User('', ''),
      loading: false,
      message: ''
    };
  },
  computed: {
    ...mapGetters( 'auth', ['isLoggedIn']),
  },

  created() {
    if (this.isLoggedIn) {
      this.$router.push('/');
    }
  },

  mounted() {
//    this.handleOauth();
  },

  methods: {
    handleOauth(){
      if (!this.isLoggedIn) {
        axios.get(Const.SERVER + "/oauth/login/bkest")
            .then(
                (usr) => {
                  console.log("LOGIN received: " + usr);
                  if(usr.roles.includes('ROLE_TEACHER')){
                    this.$router.push('/acc/home');
                  }
                  else{
                    this.loading = false;
                    this.message ="Kein Zugriff";
                    this.$router.push('/login');
                  }
                },
                error => {
                  this.loading = false;
                  this.message = "Username oder Passwort falsch";
                  console.log("Not logged in: " + error.toLocaleString());
                  this.$router.push('/login');
                }
            )
        ;
      }
    },

    handleLogin() {
      this.loading = true;
      this.$validator.validateAll().then(isValid => {
        if (!isValid) {
          this.loading = false;
          return;
        }

        if (this.user.username && this.user.password) {
          this.$store.dispatch('auth/login', this.user).then(
              (usr) => {
                if(usr.roles.includes('ROLE_TEACHER')){
                  this.$router.push('/acc/home');
                }
                else{
                  this.loading = false;
                  this.message ="Kein Zugriff";
                  this.$router.push('/login');
                }
              },
              error => {
                this.loading = false;
                this.message = "Username oder Passwort falsch";
                console.log("Not logged in: " + error.toLocaleString());
                this.$router.push('/login');
              }
          );
        }
      });
    }

  }
};
</script>

<style scoped>
label {
  display: block;
  margin-top: 10px;
}

.card-container.card {
  max-width: 350px !important;
  padding: 40px 40px;
}

.card {
  background-color: #f7f7f7;
  padding: 20px 25px 30px;
  margin: 0 auto 25px;
  margin-top: 50px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}

.profile-img-card {
  width: 96px;
  height: 96px;
  margin: 0 auto 10px;
  display: block;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}
</style>