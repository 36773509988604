import axiosInstance from "./api";
import tokenService from "./tokenService";

const setup = (store) => {
  axiosInstance.interceptors.request.use(
    (config) => {
      const token = tokenService.getLocalAccessToken();
      if (token) {
        config.headers["Authorization"] = 'Bearer ' + token;  // for Spring Boot back-end
        // config.headers["x-access-token"] = token; // for Node.js Express back-end
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  axiosInstance.interceptors.response.use(
    (res) => {
      return res;
    },
    async (err) => {
      const originalConfig = err.config;

      if (originalConfig.url !== "/auth/signin" && err.response) {
        // Access Token was expired
        if (err.response.status === 401 && !originalConfig._retry) {
          originalConfig._retry = true;

          try {
            const rs = await axiosInstance.post("/auth/refresh", {
              grant_type: 'refresh_token',
              refresh_token: tokenService.getLocalRefreshToken(),
            },
              {
                headers: {
                  'Content-Type': 'application/x-www-form-urlencoded',
//                  'Content-Type': 'application/json',
                }
              }
            );

            const { access_token } = rs.data;

            store.dispatch('auth/refreshToken', access_token);
            tokenService.updateLocalAccessToken(access_token);

            return axiosInstance(originalConfig);
          } catch (_error) {
            return Promise.reject(_error);
          }
        }
      }

      return Promise.reject(err);
    }
  );
};

export default setup;